@import 'tailwindcss/utilities';
@import 'tailwindcss/base';
@import 'tailwindcss/components';


/* Add your custom styles here */
@font-face {
  font-family: 'Akagi-Pro-bold';
  font-style: normal;
  font-weight: 400;
  src: local('Myriad Pro Condensed'), url('./App/Assets/font/Akagi-Pro-W00-Bold-1.ttf') format('woff');
}


@font-face {
  font-family: 'AkagiPro-Medium';
  font-style: normal;
  font-weight: 400;
  src: local('Myriad Pro Condensed'), url('./App/Assets/font/aptos-narrow.ttf') format('woff');
}

@font-face {
  font-family: 'AkagiPro-book';
  font-style: normal;
  font-weight: 400;
  src: local('Myriad Pro Condensed'), url('./App/Assets/font/aptos-narrow.ttf') format('woff');
}

@font-face {
  font-family: 'Akagi-Pro-blacks';
  font-style: normal;
  font-weight: 900;
  src: local('Myriad Pro Condensed'), url('./App/Assets/font/Akagi\ Pro\ W00\ Black.ttf') format('woff');
}

@font-face {
  font-family: 'AkagiPro-book-black';
  font-style: normal;
  font-weight: 400;
  src: local('Myriad Pro Condensed'), url('./App/Assets/font/aptos-narrow.ttf') format('woff');
}

.Akagi-Pro-blacks {
  font-family: 'Akagi-Pro-blacks';
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AkagiPro-book-black {
  font-family: "AkagiPro-book-black";
}

.AkagiPro-Black {
  font-family: 'Akagi-Pro-bold';
}

.AkagiPro-book {
  font-family: 'AkagiPro-book' serif;
  /* font-family: 'Roboto, sans-serif';  */

}

a {
  text-decoration: none !important;
}

.text-48 {
  font-size: 48px;
  line-height: 50px;
}

.text-64 {
  font-size: 64px;
  line-height: 62px;
}

.text-32 {
  font-size: 32px;

}

.text-20 {
  font-size: 20px;
  line-height: 26px;
}

.text-30 {
  font-size: 30px;
  line-height: 36px;
}

.text-35 {
  font-size: 35px;
  line-height: 35px;
}

.AkagiPro-Medium {
  font-family: 'AkagiPro-Medium' 'sans-serif';
  color: black;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin: auto;
}

.feddlerheader {
  font-family: 'Akagi-Pro-bold';
}

.feddlerheader ul {
  margin-left: 100px;
  gap: 40px;
}

.feddlerheader button {
  margin-left: 75px;
  margin-right: 75px;
}

.slider_left .slick-list {
  width: 100%;
}


@media (max-width:1900px) {
  .slider_left .slick-list {
    width: 1900px;
  }
}

@media (max-width:1536px) {
  .feddlerheader ul {
    margin-left: 80px;
    gap: 25px;
  }

  .feddlerheader button {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width:1400px) {
  /* .text-ellips {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 92px;

  } */
}

@media (max-width:1279px) {
  .feddlerheader ul {
    margin-left: 20px;
    gap: 6px;
  }



  .feddlerheader button {
    margin-left: 20px;
    margin-right: 20px;
  }

  .container {
    max-width: 1280px !important;
  }

  ;
}

.button_books {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

@media (max-width:1291px) {
  .button_books {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}



@media (max-width:1280px) {
  .menu_h {
    display: block !important;

  }

  .menu_button {
    display: block !important;
  }

  .header_menu {
    left: 20px;
    display: block;
  }

  .header_menu li {
    padding-left: 10px;
    margin-left: 0px !important;
  }
}

@media (max-width:1399px) {
  .Massage {
    width: 32% !important;
  }
}

@media (max-width:1204px) {
  .Massage {
    width: 38% !important;
  }
}

@media (max-width:933px) {
  .Massage {
    width: 48% !important;
  }
}

.book_button {
  background-color: #BD8833;
  color: white;
  font-size: 20px;
  padding: 12px 0px;

}

.book_button1 {
  background-color: #BD8833;
  color: white;
}

.book_button:hover,
.book_button1:hover {
  background-color: light-dark(#efedea, #223a2c);
  color: black !important;
  cursor: pointer;
}

/* footer css */
/* heder css */
.custom-select {
  position: relative;
  width: 50px;
}

.selected {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;

}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 5px;
  background-color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  width: 100px;
}

header a {
  color: white;
  text-decoration: none;
}

a {
  color: black;
  text-decoration: none;
}

.options.open {
  display: block;
  z-index: 9999;
}

.options li {
  padding: 10px;
  cursor: pointer;
}

.options li:hover {
  background-color: #f5f5f5;
  color: black;
}

/* custom slider */
.Directly .carousel-caption {
  position: relative;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding: 20px;
}

.Directly .carousel-control-next {
  display: none;
}

.Directly .carousel-control-prev {
  display: none;
}

.active a {
  color: #BD8833;

}

.black_shoadow {
  box-shadow: 0px 2px 14px 1px #00000040;
}

header .active {
  color: #BD8833 !important;
  /* Change the color to indicate the active link */
}

.restaurants_h ul {
  list-style: inherit !important;
}



.bear_data {
  transform: scale(1) !important;
  background-color: #573B0F;
}

.EVENTS_L {
  content: "";
  width: 105%;
  display: block;
  right: 70px;
  position: relative;
  border: 1.5px solid #573B0F;
  outline: 1.5px solid #573B0F;
  outline-offset: -5px;
  padding-left: 30px;
  padding-bottom: 30px;
  margin-top: 30px;
}

.options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: calc(100% + 5px);
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 5px;
  background-color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  padding: 5px 0px;
}


.left_EVENTS_L {
  right: 0px;
  padding-left: 0px;
  padding-right: 30px;
  right: -70px;
}

.left_EVENTS_L .GROUPS_ev {
  margin-left: -45px;
}

.phoneNumber button {
  background-color: transparent;
  font-size: 30px;
  padding: 0px !important;
  color: #F0D673 !important;
}

.GROUPS_ev {
  margin-top: -30px;
  margin-right: -30px;
  position: relative;
  z-index: 99999;
  width: 107%;
}

.attraction_Type .leaflet-left {
  right: 24px;
  bottom: 33px;
  top: inherit !important;
  left: inherit !important;
}

.attraction_Type {
  height: 625px;
  position: relative;
}

.leaflet-control {
  border: none !important;
}

.leaflet-touch .leaflet-bar a {
  width: 47px !important;
  height: 48px !important;
  line-height: 48px !important;
  border-bottom: none !important;
}

.attraction_Type .leaflet-marker-icon {
  height: 50px !important;
  width: 50px !important;
}

.h-490 {
  min-height: 100vh;
}

.mt_0 {
  margin-top: 16px !important;
}

.gup_ev .EVENTS_L {
  right: 0;
  width: 100%;
  margin-left: -40px;
  padding-left: 0px;
}

.gup_ev .GROUPS_ev {
  width: 100%;
  right: -40px;
}

.gup_ev .left_EVENTS_L {
  margin-left: 40px;
  padding-right: 0px;
}

.gup_ev .left_EVENTS_L .GROUPS_ev {
  left: -40px;
  margin-left: 0px;

}

.selection_select {
  padding-top: 15px;
  padding-bottom: 15px;
}

.img_animation:hover {
  transform: scale(1.2);
  transition: 1s;

}

.animtion:hover {
  transform: scale(2.3) !important;
  transition: 1s !important;
}

.img_animation {
  transition: 1s;
}

.animation_img {
  overflow: hidden;
}

.animation_img:hover {
  box-shadow: rgba(0, 0, 0, 1.35) 0px 5px 15px;
  transition: 1s;
}

.Canoe_animation:hover {
  transform: scale(1.2);
  cursor: pointer;
  /* box-shadow: 5px 5px 0px 0px rgb(41, 42, 40); */
  transition: 1s;
}

.celebrate:hover {
  box-shadow: 0px 5px 15px rgb(182, 176, 18);
}

.car_anim:hover {
  transition: 2s;
  -webkit-transform: translateZ(-8px) rotateY(180deg);
}

.car_anim {
  transition: 2s;
}

.success-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 42, 40, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.success-popup-content {
  background-color: black;
  color: green;
  font-size: 20px;
  padding: 20px;
  border-radius: 10px;
  display: grid;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.success-popup-content h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #BD8833 !important;
  margin-top: 0px;
}

.success-popup-content button {
  background-color: #BD8833;
  padding: 5px 15px;
  margin: auto;
  border-radius: 10px;
  font-size: 17px;
  color: white;

}

.success-popup-content button:hover {
  background-color: light-dark(#efedea, #223a2c);
  color: black;
}

.book_capital::first-letter {
  text-transform: uppercase;
}

.book_capital {
  text-transform: lowercase;
}

.Corporateslide_s .mt-2 p {
  text-transform: lowercase;
}

.Corporateslide_s .mt-2 {
  text-transform: uppercase;
}

.carousel-indicators button {
  height: 1px !important;
  width: 15px !important;
}

.an_button:hover {
  background-color: light-dark(#efedea, #223a2c) !important;
  color: black !important;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1470px !important;
}

.ovg .book_button:hover {
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.book_button {
  box-shadow: 0px 2px 14px 1px #00000040;
}

.box_show {
  box-shadow: none !important;
}

.black_s {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

.rabbit_data {
  transform: scale(1);
  transition: 1s;
}

.active_left:hover {
  transform: scale(1.3);
  transition: 1s;

}



.min_header .experience_data .selected {
  width: fit-content;
  padding: 0px;
  padding-right: 15px;
}

.experience_data .options {
  width: 221px;
  border: none;
  box-shadow: none;
}

.experience_data .open a {
  color: white;
}

.button_blur:hover {
  color: white !important;
  background-color: black !important;
}

@media (max-width:1280px) {
  .menu_h .xl\:space-x-8> :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0rem* var(--tw-space-x-reverse));
    margin-left: calc(0rem* calc(1 - var(--tw-space-x-reverse)));
  }
  /* .experience_data .options {
    width: 100%;
    position: absolute;
    background-color: white;
    border: none;
    box-shadow: none;
    padding-top: 7px;
    left: 126px;
    padding-left: 11px;
    padding: 11px;
    top: -4px;
} */
  .experience_data .options {
    width: 100%;
    position: relative;
    background-color: white;
    border: none;
    box-shadow: none;
    padding-top: 15px;

  }
  .md_p{
    padding-right: 15px;
  }
  .experience_data .open a {
    color: black;
  }

  .experience_data .options li {
    padding: 10px;
    cursor: pointer;
    padding: 4px 0px;
    border-bottom: 1px solid #ccc;
  }

  .min_header .experience_data .selected {
    width: fit-content;
    padding: 0px;
    padding-right: 0px;
  }

  .experience_data .dropdown-icon img {
    filter: brightness(0.5);
  }

  .header_menu {
    padding-right: 0.25rem !important;
    margin-top: 27px !important;
  }

  .experience_data .selected {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    position: unset
  }
}

.button_yellow:hover {
  background-color: light-dark(#efedea, #223a2c);
  color: black !important;
}

.button_yellow {
  border: 1px solid #18140D;
}

.ACTIVITIES_data .text-center {
  max-width: 1150px;
}

/* pdf */
#toolbar {
  display: none;
}

.second_menu::before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
}

.second_menu {
  position: relative;
}

.second_menu .text-white {
  z-index: 999;
}

.family_bg .bg-no-repeat {
  background-position: bottom !important;

}

.collapse_txte {
  overflow: hidden;

  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  height: 137px !important;
}

.book_now {
  width: fit-content !important;
  padding: 10px 10px !important;
  height: fit-content !important;
  font-family: 'Akagi-Pro-bold' !important;
  font-size: 20px !important;
}

@media (max-width:1024px) {
  .book_now {
    padding: 10px 10px !important;
    font-size: 15px !important;

  }
}

@media (max-width:767px) {
  .collapse_txte {

    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 40px !important;
  }

  .book_now {
    padding: 5px 7px !important;
    font-size: 12px !important;
  }
}

.Services_button {
  background-color: light-dark(#efedea, #223a2c) !important;
  color: black !important;
}

.Services_button:hover {
  background-color: black !important;
  color: white !important;
}

@media (max-width:447px) {
  .text-10 {
    font-size: 10px !important;
  }
}

.colleagues .img_animation {
  max-width: fit-content !important;
  width: 100% !important;
}

.BabyGear .img_animation {
  max-width: fit-content !important;
  width: 100% !important;
}